import { WebCartItem } from '@/models/Cart';
import { ProductSearchResultProduct } from '@/models/Product';

export default class TrackingService {

    public trackAddToCart(item: WebCartItem) {

        if (!item)
            return;

        // facebook pixel tracking
        if (typeof (window as any).fbq === 'function' && item.quantity > 0)
        {
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

            (window as any).fbq('track', 'AddToCart', {
                'content_name': item.name,
                'content_category': '',
                'content_ids': [item.productid],
                'content_type': 'product',
                'value': unitpriceincvat.toFixed(2),
                'currency': item.unitprice.currency
            });
        }

        // ga4 tracking
        if (typeof (window as any).gtag === 'function' && item.quantity > 0)
        {
            const linepriceincvat = item.unitprice.price * item.quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).gtag('event', 'add_to_cart', {
                currency: item.unitprice.currency,
                items: [{
                  'item_id': item.productid,
                  'item_name': item.name,
                  coupon: '',
                  discount: unitpricediscountamountincvat.toFixed(2),
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                  currency: item.unitprice.currency,
                  quantity: item.quantity
                }],
                value: linepriceincvat.toFixed(2)
            });
        }

        // gtm tracking
        if (typeof (window as any).dataLayer === 'object')
        {
            const event = item.quantity > 0 ? "add_to_cart" : "remove_from_cart";
            const quantity = item.quantity > 0 ? item.quantity : (-1 * item.quantity);
            const linepriceincvat = item.unitprice.price * quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            (window as any).dataLayer.push({
                event: event,
                ecommerce: {
                    currency: item.unitprice.currency,
                    value: linepriceincvat,
                    items: [{
                        'item_id': item.productid,
                        'item_name': item.name,
                        discount: unitpricediscountamountincvat,
                        price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                        currency: item.unitprice.currency,
                        quantity: quantity
                    }],
                }
            });
        }

        // piwik pro tracking
        if (typeof (window as any)._paq === 'object')
        {
            const quantity = item.quantity > 0 ? item.quantity : (-1 * item.quantity);
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

            (window as any)._paq.push([
                item.quantity > 0 ? "ecommerceAddToCart" : "ecommerceRemoveFromCart",
                [{
                    sku: item.productid,
                    name: item.name,
                    price: unitpriceincvat.toFixed(2),
                    quantity: quantity
                }]
            ]);
        }
    }

    public trackViewItemList(items: ProductSearchResultProduct[]) {
        // gtm tracking
        const viewListId = (window as any).viewListId;
        const viewListName = (window as any).viewListName;
        if (typeof (window as any).dataLayer === 'object' && viewListId)
        {
            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            (window as any).dataLayer.push({
                event: 'view_item_list',
                eccommerce: {
                    'item_list_id': viewListId,
                    'item_list_name': viewListName,
                    items: items.map(item => {
                        return {
                            'item_id': item.id,
                            'item_name': item.name,
                            discount: item.price.discountamountexvatasnumeric,
                            price: item.price.priceexvatasnumeric,
                            currency: item.price.currency,
                            quantity: item.colli
                        };
                    }),
                }
            });
        }
    }

    public trackEmptyCart(items: WebCartItem[]) {

        if (!items?.length)
        return;

        // piwik pro tracking
        if (typeof (window as any)._paq === 'object')
        {
            (window as any)._paq.push([
                "ecommerceRemoveFromCart",
                items.map(item => {
                    const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

                    return {
                        sku: item.productid,
                        name: item.name,
                        price: unitpriceincvat.toFixed(2),
                        quantity: item.quantity
                    }
                }),
            ]);
        }
    }
}
