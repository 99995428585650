import { AssociatedUser, ChangePasswordRequest, RequestNewPasswordRequest, RequestNewPasswordResponse, SignInRequest, SignInResponse } from '@/models/SignIn';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import axios, { AxiosResponse } from 'axios';

export default class UserService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/user`;
    }

    public getLoginUrl(): string {
        return `${ CbxVariablesModule.VARIABLES.loginpage }`;
    }

    public async changePassword(oldpassword: string, newpassword: string): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}/changepassword?oldpassword=${encodeURIComponent(oldpassword)}&newpassword=${encodeURIComponent(newpassword)}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async changePasswordV2(payload: ChangePasswordRequest): Promise<string> {
        try {
            const fd = new FormData();
            fd.append('actionid', 'resetpassword');
            fd.append('output', 'json');
            fd.append('newpassword', encodeURIComponent(payload.newpassword));
            fd.append('confirmnewpassword', encodeURIComponent(payload.confirmnewpassword));
            const response: AxiosResponse = await axios({
                url: `${CbxVariablesModule.VARIABLES.loginpage}?json=true&resetkey=${payload.resetkey}`,
                method: 'POST',
                data: fd,
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (e) {
            return '/';
        }
    }

    public async signIn(payload: SignInRequest): Promise<SignInResponse> {
        try {
            const fd = new FormData();
            fd.append('actionid', 'signin');
            fd.append('output', 'json');
            fd.append('username', payload.username);
            fd.append('password', encodeURIComponent(payload.password));
            if (payload.remember)
                fd.append('rememberlogin', 'true');
            const response: AxiosResponse = await axios({
                url: `${ CbxVariablesModule.VARIABLES.loginpage }?json=true`,
                method: 'POST',
                data: fd,
                headers: {'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (e) {
            return {
                errortype: 1,
                signinerror: true,
            };
        }
    }

    public async signOut(): Promise<boolean> {
        try {
            await axios({
                url: `${ CbxVariablesModule.VARIABLES.loginpage }?actionid=signout&signout=true`,
                method: 'GET',
            });
            return true;
        } catch (e) {
            return false;
        }
    }

    public async requestNewPassword(payload: RequestNewPasswordRequest): Promise<RequestNewPasswordResponse> {
        try {
            const fd = new FormData();
            fd.append('actionid', 'resetpasswordrequest');
            fd.append('output', 'json');
            fd.append('email', payload.email);
            const response: AxiosResponse = await axios({
                url: `${ CbxVariablesModule.VARIABLES.loginpage }?json=true`,
                method: 'POST',
                data: fd,
                headers: {'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (e) {
            return {
                emailmissing: true,
            };
        }
    }

    public async getAssociatedUsers(): Promise<AssociatedUser[]> {
        try {
            const res: AxiosResponse<AssociatedUser[]> = await axios.get(`${this.endpoint}/associated`);
            return res.data;
        } catch (e) {
            return [];
        }
    }

    public async changeUser(id: string): Promise<SignInResponse> {
        try {
            const fd = new FormData();
            fd.append('actionid', 'changeuser');
            fd.append('key', id);
            const response: AxiosResponse<SignInResponse> = await axios({
                url: `${ CbxVariablesModule.VARIABLES.loginpage }`,
                method: 'POST',
                data: fd,
                headers: {'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (e) {
            return {
                errortype: 1,
                signinerror: true,
            };
        }
    }

}
